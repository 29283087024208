<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.id"
              placeholder="话题ID"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.keyword"
              placeholder="圈子名称/描述"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        <a-button  type="primary" @click="add" style="margin-left:20px;" icon="plus">
          新增
        </a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="images" slot-scope="text, record">
        <div v-if="text.logo" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(text.logo || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(text.logo || '').split(',')"
          />
        </div>
      </template>
      <template slot="owner" slot-scope="text, record">
        <div>{{ record.creator && record.creator.username }}</div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('validStatus', 'color')">{{
            record.status | dictName("validStatus")
          }}
        </a-tag>
      </template>
      <template slot="isTop" slot-scope="text, record">
        <a-switch :checked="text==1" @change="changeTopState(record,'isTop')" :loading="showLoading"/>
      </template>

      <template slot="hotTop" slot-scope="text, record">
        <a-switch :checked="text==1" @change="changeTopState(record,'hotTop')" :loading="showLoading"/>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-dropdown
            v-hasAnyPermission="['topic:view', 'topic:edit', 'topic:delete']"
        >
          <a-menu slot="overlay">
            <a-menu-item key="modify" @click="modify($event, record)"
                         v-hasPermission="'topic:edit'">
              <a-icon type="setting"/>
              修改
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="delete"
                style="color: red"
                v-hasPermission="'topic:delete'"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyTopicModal.vue";

export default {
  name: "CircleList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "topic/listByAdmin",
      listMethod: "postJson",
      showLoading:false
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      this.dataSource = this.dataSource.map((item) => {
        try {
          item.media = JSON.parse(item.media) || [];
        } catch (error) {
          item.media = [];
        }
        return item;
      });
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "头像",
          width: 100,
          align: "center",
          // dataIndex: "avatar",
          scopedSlots: {customRender: "images"},
        },
        {
          title: "话题",
          align: "center",
          width: 300,
          dataIndex: "topicTitle",
        },
        {
          title: "描述",
          width: 350,
          align: "center",
          dataIndex: "topicDesc",
        },
        {
          title: "题主",
          width: 150,
          align: "center",
          scopedSlots: {customRender: "owner"},
        },
        // {
        //   title: "所属圈子",
        //   align: "center",
        //   width: 150,
        //   dataIndex: "circleName",
        // },
        {
          title: "帖子数量",
          align: "center",
          width: 80,
          dataIndex: "postCount",
        },
        {
          title: "查看次数",
          align: "center",
          width: 80,
          dataIndex: "viewCount",
        },
        {
          title: "状态",
          align: "center",
          width: 80,
          scopedSlots: {customRender: "status"},
        },
        {
          title: "是否推荐",
          align: "center",
          width: 80,
          dataIndex: "isTop",
          scopedSlots: {customRender: "isTop"},
        },
        {
          title: "是否热门",
          align: "center",
          width: 80,
          dataIndex: "hotTop",
          scopedSlots: {customRender: "hotTop"},
        },
        {
          title: "排序序号",
          align: "center",
          width: 80,
          dataIndex: "sortNumber",
        },
        {
          title: "建立时间",
          width: 150,
          align: "center",
          dataIndex: "createDate",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        // {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record,'modify');
    },
    add(){
      this.$refs.modifyModal.showModal(null,'new');
    },
    changeTopState(record, field = 'isTop') {
      let that = this;
      let data = {
        id: record.id,
      };
      data[field] = record[field] == 1 ? 0 : 1;
      that.showLoading = true;
      that.$postJson("topic/update", data).then(() => {
        that.showLoading = false;
        let mes =
            record[field] != 1
                ? "ID：【" + record.id + "】设置" + (field === 'isTop' ? "推荐" : "热门") + "成功"
                : "ID：【" + record.id + "】取消" + (field === 'isTop' ? "推荐" : "热门") + "成功"
        that.$message.success(mes + "，请前往小程序查看");
        this.search();
      }).catch(()=>{
        that.showLoading = false;
      });
    },
    getDelete(e, record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除话题【" + record.topicTitle + "】吗？！",
        centered: true,
        onOk() {
          that.$postJson("topic/delete", [record.id]).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
